import Column from '@/model/shared/Column';
// import FILTERS_CONFIG from '@/model/modules/dashboardSSP/filtersConfig.js';

export const COLUMNS = {
  NAME: new Column('NAME').setClass('break-normal font-bold'),
  TRANSACTION_ID: new Column('TRANSACTION ID', 'idOpportunity'),
  TYPE: new Column('TYPE', 'type'),
  SELLER: new Column('SELLER', 'seller.email'),
  ACTIONS: new Column('ACTIONS', 'actions', true, false, [], 'w-4'),
};

export default {
  columns: Object.values(COLUMNS),
  filters: [
    // FILTERS_CONFIG.DEAL_NAME,
    // FILTERS_CONFIG.DEAL_TYPE,
    // FILTERS_CONFIG.SALESFORCE_SELLER_ID,
    // FILTERS_CONFIG.TRANSACTION_ID,
  ],
};
