<template>
  <deal-form :entity="deal" :is-loading="isLoading" @update="loadDeal"></deal-form>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import DealForm from '@/components/organisms/modules/dashboardSSP/deal/form/DealForm.vue';
import metaInfo from '@/mixins/common/metaInfo';
import { list } from '@/router/private/modules/dashboardSSP/supply/salesforcesDeals/list';
import { getDealById } from '@/services/modules/dashboardSSP/deal/getDealById';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'SalesforcesDealEdit',
  components: {
    DealForm,
  },
  mixins: [metaInfo],
  props: {
    dealId: { type: String, default: () => null },
  },
  data: () => ({
    deal: null,
    isLoading: true,
  }),
  created() {
    this.loadDeal();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async loadDeal() {
      try {
        if (!this.dealId) return;
        this.isLoading = true;

        const { data } = await getDealById(this.dealId);
        this.deal = data;
        this.updateTitle();
        this.isLoading = false;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Can't get deal`, error.message));
          await this.$router.push(list);
        } else {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
