<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="min-w-max">
              <sun-search-input
                class-input="text-sm"
                :value="filterQuick['deal.name']"
                @search="onQuickFiltersSearch($event, 'deal.name')"
              />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.deal.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.deal.idOpportunity`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.deal.type`]="{ filter, onSelect, value, isDuplicate }">
            <asterix-async-select
              id="seller-select-filter"
              name="seller-select-filter"
              track-by="name"
              label="name"
              class="w-24"
              is-array-object
              disable-selected-options
              close-on-select
              :service="filter.service"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :value="filtersMakeFiltersForSelect(value)"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.items.name, onSelect)"
            />
          </template>

          <template #[`filter.deal.sellerId`]="{ filter, onSelect, value, isDuplicate }">
            <asterix-async-select
              id="seller-select-filter"
              name="seller-select-filter"
              track-by="id"
              label="name"
              class="w-24"
              is-array-object
              disable-selected-options
              close-on-select
              :service="filter.service"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :value="filtersMakeFiltersForSelect(value)"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
            />
          </template>
        </sun-filter-layout>

        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          :loading="isLoading"
          class="mt-2"
          @sort="onSortTable"
        >
          <template #[`col.seller.email`]="{ item }">
            <sun-data-table-cell>
              {{ salesforceUserEmailList[item.seller.id] || notAvailable }}
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination
          :key="currentPage"
          class="justify-center mt-12"
          :total-pages="totalPages"
          :current-page="currentPage"
          @change="goToPage"
        />
      </template>
    </asterix-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { getDeals } from '@/services/modules/dashboardSSP/deal/getDeals';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { edit as editSalesforcesDeal } from '@/router/private/modules/dashboardSSP/supply/salesforcesDeals/edit';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import CONFIG from './config';
import getSaleforceUsersForDealsMixin from '@/mixins/modules/dashboardSSP/getSaleforceUsersForDealsMixin';
import { USER } from '@/store/modules/auth/keys';

export default {
  name: 'SalesforcesDealLis',
  components: {
    AsterixSection,
    AsterixAsyncSelect,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        'deal.name': undefined,
      },
    }),
    indexMixin,
    getSaleforceUsersForDealsMixin,
  ],
  data: () => ({
    headers: CONFIG.columns,
    items: [],
    actions: [{ name: 'Show' }],
    notAvailable: 'N/A',
  }),
  computed: {
    ...mapGetters({
      user: USER,
    }),
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    onActionClick(event, deal) {
      switch (event.name) {
        case 'Show':
          this.editDeal(deal.id);
          break;
      }
    },
    editDeal(dealId) {
      const route = { name: editSalesforcesDeal.name, params: { dealId } };
      this.$router.push(route);
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getDeals);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
      await this.setSellers('seller');
    },
  },
};
</script>
