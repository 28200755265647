var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.availableFilters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    change: _vm.filtersSetFiltersFind,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "min-w-max" },
                              [
                                _c("sun-search-input", {
                                  attrs: {
                                    "class-input": "text-sm",
                                    value: _vm.filterQuick["deal.name"],
                                  },
                                  on: {
                                    search: function ($event) {
                                      return _vm.onQuickFiltersSearch(
                                        $event,
                                        "deal.name"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.deal.name`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.deal.idOpportunity`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.deal.type`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("asterix-async-select", {
                              staticClass: "w-24",
                              attrs: {
                                id: "seller-select-filter",
                                name: "seller-select-filter",
                                "track-by": "name",
                                label: "name",
                                "is-array-object": "",
                                "disable-selected-options": "",
                                "close-on-select": "",
                                service: filter.service,
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.items.name,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.deal.sellerId`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("asterix-async-select", {
                              staticClass: "w-24",
                              attrs: {
                                id: "seller-select-filter",
                                name: "seller-select-filter",
                                "track-by": "id",
                                label: "name",
                                "is-array-object": "",
                                "disable-selected-options": "",
                                "close-on-select": "",
                                service: filter.service,
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.items,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  staticClass: "mt-2",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    hoverable: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.seller.email`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.salesforceUserEmailList[
                                      item.seller.id
                                    ] || _vm.notAvailable
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination", {
                  key: _vm.currentPage,
                  staticClass: "justify-center mt-12",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "current-page": _vm.currentPage,
                  },
                  on: { change: _vm.goToPage },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }