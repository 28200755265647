var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("meta-actions-header", {
        staticClass: "mb-12",
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "breadcrumb",
                        { staticClass: "flex flex-1 text-gray-700" },
                        [
                          _vm._v(": "),
                          _vm.deal
                            ? _c("span", { staticClass: "text-orange-500" }, [
                                _vm._v(_vm._s(_vm.deal.name)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      !_vm.isLoading
        ? _c("tabs", {
            attrs: { deal: _vm.deal, "tabs-data": _vm.tabs },
            on: {
              locked: _vm.onTabLocked,
              dirty: _vm.onTabDirty,
              update: _vm.onDealInfoUpdate,
            },
          })
        : _vm._e(),
      _vm.isLoading ? _c("card", [_c("card-form-loading")], 1) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }